import { GlobalStyles, themes } from '@unbounded/unbounded-components';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import Loader from '~components/Loader';
import { LOADED_THEME } from '~persistence/theme';
import { getPlatform } from '~platform/platform';
const IndexLoader = () => (React.createElement(ThemeProvider, { theme: themes[LOADED_THEME] },
    React.createElement(GlobalStyles, null),
    React.createElement(Loader, { placeholder: `${getPlatform().name} is starting...` })));
export default IndexLoader;
