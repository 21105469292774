import React from 'react';
import PlatformLogo from '~components/PlatformLogo';
import getApplicationConfig from '~platform';
import { getPlatform } from '~platform/platform';
import * as Styled from './styles';
const PlatformHeader = divProps => {
    const { apps } = getApplicationConfig();
    const { motto: caption, key: platformKey } = getPlatform();
    return (React.createElement(Styled.Container, { ...divProps },
        React.createElement(Styled.Logo, { href: apps[platformKey].url, target: "_blank", rel: "noreferrer" },
            React.createElement(PlatformLogo, { platformKey: platformKey })),
        caption && React.createElement(Styled.Caption, null, caption)));
};
export default PlatformHeader;
