import papillonLogo from '~assets/papillon.svg';
const CONFIGURATION = {
    name: 'Papillon',
    key: 'papillon',
    motto: 'Developer Freedom',
    logo: papillonLogo,
    linkPrivacyPolicy: 'https://www.mipasa.com/privacy-policy',
    linkTermsOfUse: 'https://www.mipasa.com/terms-of-use',
};
export default CONFIGURATION;
