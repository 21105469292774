import { LoadingAreaStyles, rem } from '@unbounded/unbounded-components';
import styled from 'styled-components';
export const Container = styled.div `
  position: relative;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ withTitle }) => rem(withTitle ? 148 : 120)} 0 ${rem(120)};
`;
export const PlatformHeaderContainer = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;
export const Title = styled(LoadingAreaStyles.LoadingTitle) ``;
export const Placeholder = styled(LoadingAreaStyles.Placeholder) ``;
