import pasaLogo from '~assets/pasa.svg';
const CONFIGURATION = {
    name: 'Pasa',
    key: 'crypto',
    motto: 'Verifiable Crypto Flows',
    logo: pasaLogo,
    linkPrivacyPolicy: 'https://unbounded.mipasa.com/privacy',
    linkTermsOfUse: 'https://unbounded.mipasa.com/terms',
};
export default CONFIGURATION;
