import { PlatformLoader } from '@unbounded/unbounded-components';
import React from 'react';
import PlatformHeader from '~components/PlatformHeader';
import { getPlatform } from '~platform/platform';
import * as Styled from './styles';
const Loader = ({ title, placeholder = 'Loading...', children }) => (React.createElement(Styled.Container, { withTitle: !!title },
    React.createElement(Styled.PlatformHeaderContainer, null,
        React.createElement(PlatformHeader, null)),
    React.createElement(PlatformLoader, { platform: getPlatform().key }),
    !!title && React.createElement(Styled.Title, null, title),
    React.createElement(Styled.Placeholder, null, placeholder),
    children));
export default Loader;
