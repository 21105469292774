import { BaseColor, linkReset, rem, spacing, themeColor, Typography } from '@unbounded/unbounded-components';
import styled from 'styled-components';
export const Container = styled.div `
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing(0.5)};
  margin: ${spacing(0.75)} auto ${spacing()};
  padding: ${spacing()};
  text-align: center;
  align-self: flex-start;
`;
export const Logo = styled.a `
  ${linkReset};
  display: flex;
  min-height: ${rem(28)};
  color: ${themeColor(BaseColor.shade_100)};
  cursor: pointer;

  svg {
    width: auto;
    min-height: ${rem(28)};
  }
`;
export const Caption = styled.div `
  ${Typography.bodySmall};
  color: ${themeColor(BaseColor.max)};
`;
