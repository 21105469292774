import mipasaLogo from '~assets/mipasa.svg';
const CONFIGURATION = {
    name: 'MiPasa',
    key: 'mipasa',
    motto: 'Analytics for Everyone — Anytime, Anywhere™',
    logo: mipasaLogo,
    linkPrivacyPolicy: 'https://www.mipasa.com/privacy-policy',
    linkTermsOfUse: 'https://www.mipasa.com/terms-of-use',
};
export default CONFIGURATION;
